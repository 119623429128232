/* eslint-disable @next/next/no-img-element */
import { NextSeo } from 'next-seo'
import { useEffect, useState } from 'react'
import classnames from 'classnames'
import { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { t, Trans } from '@lingui/macro'
import { InView } from 'react-intersection-observer'
import Eventemitter from '@/utils/eventEmitter'

import { getUrlParams } from '@/utils/getUrlParams'
import FooterFP from '@/components/Footer'
import AgreeCookiePolicy from '@/components/AgreeCookiePolicy'
// import CommonHeader2 from '@/components/CommonHeader2'
// import { Sidebar2 } from '@/components/Sidebar2'
import Button from '@/components/Button'
import CustomDialog from '@/components/CustomDialog'
import ipServices from '@/services/ip'

import 'swiper/css'
import 'swiper/css/pagination'
import styles from './index.module.scss'
import { loadCatalog } from '@/utils/i18n'
import dynamic from 'next/dynamic'

const CommonHeader2 = dynamic(() => import('@/components/CommonHeader2'), { ssr: false })
const Sidebar2 = dynamic(() => import('@/components/Sidebar2'), { ssr: false })

export const getStaticProps = async (ctx) => {
  const translation = await loadCatalog('en')
  return {
    props: {
      translation,
    },
  }
}

function Palau() {
  const [open, setOpen] = useState(false)
  const [display, setDisplay] = useState(false)
  let timer = null

  // 首页文案
  const BENEFITS = [
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M9.03349 16.2197L19.3997 5.85355C19.5781 5.67509 19.79 5.58393 20.0354 5.58007C20.2808 5.57623 20.4966 5.66739 20.6827 5.85355C20.8689 6.03969 20.9619 6.25353 20.9619 6.49507C20.9619 6.73659 20.8689 6.95043 20.6827 7.13659L9.7927 18.0266C9.57578 18.2435 9.32271 18.3519 9.03349 18.3519C8.74427 18.3519 8.4912 18.2435 8.27428 18.0266L3.26428 13.0166C3.08582 12.8381 2.99774 12.6262 3.00004 12.3808C3.00236 12.1355 3.09659 11.9197 3.28273 11.7336C3.46889 11.5474 3.68273 11.4543 3.92425 11.4543C4.16579 11.4543 4.37963 11.5474 4.56577 11.7336L9.03349 16.2197Z"
            fill="#0354BF"
          />
        </svg>
      ),
      text: <Trans>ID verification for: check-in, boarding, rental</Trans>,
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M9.03349 16.2197L19.3997 5.85355C19.5781 5.67509 19.79 5.58393 20.0354 5.58007C20.2808 5.57623 20.4966 5.66739 20.6827 5.85355C20.8689 6.03969 20.9619 6.25353 20.9619 6.49507C20.9619 6.73659 20.8689 6.95043 20.6827 7.13659L9.7927 18.0266C9.57578 18.2435 9.32271 18.3519 9.03349 18.3519C8.74427 18.3519 8.4912 18.2435 8.27428 18.0266L3.26428 13.0166C3.08582 12.8381 2.99774 12.6262 3.00004 12.3808C3.00236 12.1355 3.09659 11.9197 3.28273 11.7336C3.46889 11.5474 3.68273 11.4543 3.92425 11.4543C4.16579 11.4543 4.37963 11.5474 4.56577 11.7336L9.03349 16.2197Z"
            fill="#0354BF"
          />
        </svg>
      ),
      text: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Trans>KYC for</Trans>: $ € £ / ₿ Ξ ₮...
        </div>
      ),
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M9.03349 16.2197L19.3997 5.85355C19.5781 5.67509 19.79 5.58393 20.0354 5.58007C20.2808 5.57623 20.4966 5.66739 20.6827 5.85355C20.8689 6.03969 20.9619 6.25353 20.9619 6.49507C20.9619 6.73659 20.8689 6.95043 20.6827 7.13659L9.7927 18.0266C9.57578 18.2435 9.32271 18.3519 9.03349 18.3519C8.74427 18.3519 8.4912 18.2435 8.27428 18.0266L3.26428 13.0166C3.08582 12.8381 2.99774 12.6262 3.00004 12.3808C3.00236 12.1355 3.09659 11.9197 3.28273 11.7336C3.46889 11.5474 3.68273 11.4543 3.92425 11.4543C4.16579 11.4543 4.37963 11.5474 4.56577 11.7336L9.03349 16.2197Z"
            fill="#0354BF"
          />
        </svg>
      ),
      text: <Trans>+180 days stay per entry</Trans>,
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M9.03349 16.2197L19.3997 5.85355C19.5781 5.67509 19.79 5.58393 20.0354 5.58007C20.2808 5.57623 20.4966 5.66739 20.6827 5.85355C20.8689 6.03969 20.9619 6.25353 20.9619 6.49507C20.9619 6.73659 20.8689 6.95043 20.6827 7.13659L9.7927 18.0266C9.57578 18.2435 9.32271 18.3519 9.03349 18.3519C8.74427 18.3519 8.4912 18.2435 8.27428 18.0266L3.26428 13.0166C3.08582 12.8381 2.99774 12.6262 3.00004 12.3808C3.00236 12.1355 3.09659 11.9197 3.28273 11.7336C3.46889 11.5474 3.68273 11.4543 3.92425 11.4543C4.16579 11.4543 4.37963 11.5474 4.56577 11.7336L9.03349 16.2197Z"
            fill="#0354BF"
          />
        </svg>
      ),
      text: <Trans>0% tax imposed for outside-of-Palau income</Trans>,
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M9.03349 16.2197L19.3997 5.85355C19.5781 5.67509 19.79 5.58393 20.0354 5.58007C20.2808 5.57623 20.4966 5.66739 20.6827 5.85355C20.8689 6.03969 20.9619 6.25353 20.9619 6.49507C20.9619 6.73659 20.8689 6.95043 20.6827 7.13659L9.7927 18.0266C9.57578 18.2435 9.32271 18.3519 9.03349 18.3519C8.74427 18.3519 8.4912 18.2435 8.27428 18.0266L3.26428 13.0166C3.08582 12.8381 2.99774 12.6262 3.00004 12.3808C3.00236 12.1355 3.09659 11.9197 3.28273 11.7336C3.46889 11.5474 3.68273 11.4543 3.92425 11.4543C4.16579 11.4543 4.37963 11.5474 4.56577 11.7336L9.03349 16.2197Z"
            fill="#0354BF"
          />
        </svg>
      ),
      text: <Trans>Choice of citizens from 138 countries</Trans>,
    },
  ]

  const BENEFITS2 = [
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M9.125 10.8125L7.875 9.56249C7.72728 9.40971 7.55493 9.33332 7.35796 9.33332C7.16099 9.33332 6.98611 9.40971 6.83333 9.56249C6.68056 9.71527 6.60417 9.89351 6.60417 10.0972C6.60417 10.3009 6.68056 10.4768 6.83333 10.625L8.58333 12.3958C8.73485 12.5486 8.91162 12.625 9.11365 12.625C9.31566 12.625 9.49306 12.5486 9.64583 12.3958L13.1875 8.85416C13.3403 8.70138 13.4167 8.52777 13.4167 8.33332C13.4167 8.13888 13.3403 7.96527 13.1875 7.81249C13.0347 7.65971 12.8565 7.58332 12.6528 7.58332C12.4491 7.58332 12.2732 7.65971 12.125 7.81249L9.125 10.8125ZM10 17.9167C9.91667 17.9167 9.83333 17.9132 9.75 17.9062C9.66667 17.8993 9.59028 17.8819 9.52083 17.8542C7.70139 17.2569 6.24306 16.1528 5.14583 14.5417C4.04861 12.9305 3.5 11.1736 3.5 9.27082V5.52082C3.5 5.20566 3.58681 4.922 3.76042 4.66986C3.93403 4.41773 4.16667 4.2361 4.45833 4.12499L9.45833 2.20832C9.63889 2.13888 9.81944 2.10416 10 2.10416C10.1806 2.10416 10.3611 2.13888 10.5417 2.20832L15.5417 4.12499C15.8333 4.2361 16.066 4.41773 16.2396 4.66986C16.4132 4.922 16.5 5.20566 16.5 5.52082V9.27082C16.5 11.1736 15.9514 12.9305 14.8542 14.5417C13.7569 16.1528 12.2986 17.2569 10.4792 17.8542C10.4097 17.8819 10.3333 17.8993 10.25 17.9062C10.1667 17.9132 10.0833 17.9167 10 17.9167ZM10 16.4375C11.4444 15.9896 12.6389 15.0937 13.5833 13.75C14.5278 12.4062 15 10.9132 15 9.27082V5.52082L10 3.60416L5 5.52082V9.27082C5 10.9132 5.47222 12.4062 6.41667 13.75C7.36111 15.0937 8.55556 15.9896 10 16.4375Z"
            fill="#0355BF"
          />
        </svg>
      ),
      text: <Trans>Seamless on-chain identity verification</Trans>,
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M4.875 13.25L6.54167 14.9167C6.69444 15.0694 6.77083 15.2477 6.77083 15.4514C6.77083 15.6551 6.69444 15.831 6.54167 15.9792C6.39351 16.1319 6.21759 16.2083 6.0139 16.2083C5.81019 16.2083 5.63194 16.1319 5.47917 15.9792L2.52083 13.0208C2.45139 12.9458 2.39931 12.8646 2.36458 12.7771C2.32986 12.6896 2.3125 12.5958 2.3125 12.4958C2.3125 12.3958 2.32986 12.3021 2.36458 12.2146C2.39931 12.1271 2.45139 12.0486 2.52083 11.9792L5.47917 9.02084C5.63194 8.86806 5.80903 8.79167 6.01042 8.79167C6.21181 8.79167 6.39221 8.86806 6.55163 9.02084C6.69776 9.17362 6.77083 9.3507 6.77083 9.55209C6.77083 9.75348 6.69444 9.93056 6.54167 10.0833L4.875 11.75H10.25C10.4625 11.75 10.6406 11.8215 10.7844 11.9644C10.9281 12.1073 11 12.2844 11 12.4956C11 12.7069 10.9281 12.8854 10.7844 13.0313C10.6406 13.1771 10.4625 13.25 10.25 13.25H4.875ZM15.125 8.25001H9.75C9.5375 8.25001 9.35938 8.17855 9.21563 8.03565C9.07188 7.89273 9 7.71565 9 7.5044C9 7.29314 9.07188 7.11459 9.21563 6.96876C9.35938 6.82292 9.5375 6.75001 9.75 6.75001H15.125L13.4583 5.08334C13.3056 4.93056 13.2292 4.75232 13.2292 4.54861C13.2292 4.34491 13.3056 4.16899 13.4583 4.02084C13.6065 3.86806 13.7824 3.79167 13.9861 3.79167C14.1898 3.79167 14.3681 3.86806 14.5208 4.02084L17.4792 6.97917C17.5486 7.05417 17.6007 7.13542 17.6354 7.22292C17.6701 7.31042 17.6875 7.40417 17.6875 7.50417C17.6875 7.60417 17.6701 7.69792 17.6354 7.78542C17.6007 7.87292 17.5486 7.95139 17.4792 8.02084L14.5208 10.9792C14.3731 11.1319 14.2008 11.2049 14.0038 11.1979C13.8068 11.191 13.6286 11.1111 13.4692 10.9583C13.3231 10.8056 13.25 10.6285 13.25 10.4271C13.25 10.2257 13.3264 10.0486 13.4792 9.89584L15.125 8.25001Z"
            fill="#0355BF"
          />
        </svg>
      ),
      text: <Trans>Convert your legal ID to LDID (Existing Jurisdiction)</Trans>,
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M9.20841 15.4327V10.4631L5.00006 8.03043V12.8557C5.00006 12.8985 5.01075 12.9386 5.03212 12.976C5.05348 13.0134 5.08553 13.0454 5.12827 13.0721L9.20841 15.4327ZM10.7917 15.4327L14.8719 13.0721C14.9146 13.0454 14.9466 13.0134 14.968 12.976C14.9894 12.9386 15.0001 12.8985 15.0001 12.8557V8.03043L10.7917 10.4631V15.4327ZM10.0001 9.07373L14.3126 6.59937L10.1283 4.17629C10.0855 4.14958 10.0428 4.13623 10.0001 4.13623C9.95732 4.13623 9.91459 4.14958 9.87185 4.17629L5.68754 6.59937L10.0001 9.07373ZM4.08662 14.2949C3.87583 14.1714 3.71149 14.0062 3.5936 13.7993C3.4757 13.5924 3.41675 13.3655 3.41675 13.1186V6.86058C3.41675 6.61808 3.4757 6.3952 3.5936 6.19193C3.71149 5.98866 3.87583 5.82639 4.08662 5.70514L9.33019 2.66189C9.54141 2.53903 9.76516 2.4776 10.0014 2.4776C10.2377 2.4776 10.4605 2.53903 10.6699 2.66189L15.9135 5.70514C16.1243 5.82639 16.2886 5.98866 16.4065 6.19193C16.5244 6.3952 16.5834 6.61808 16.5834 6.86058V13.1394C16.5834 13.3819 16.5244 13.6048 16.4065 13.8081C16.2886 14.0113 16.1243 14.1736 15.9135 14.2949L10.6699 17.3381C10.4587 17.461 10.235 17.5224 9.99869 17.5224C9.76242 17.5224 9.53959 17.461 9.33019 17.3381L4.08662 14.2949Z"
            fill="#0355BF"
          />
        </svg>
      ),
      text: <Trans>Mint corresponding LDID (Existing Jurisdiction) on Ethereum</Trans>,
    },
  ]

  const LAW_TITLE = (
    <Trans>The Republic of Palau kindly asks you to respect the laws of your current place of residence.</Trans>
  )

  const LAW_TIPS = [
    <Trans>Palau Digital Residency ID only enables you to gain access to services that are available to Palau.</Trans>,
    <Trans>
      Please note, a Palau Digital Residency ID serves as an identification document, not a certification of
      citizenship.
    </Trans>,
    <Trans>Palau does not impose taxes on income you make outside of Palau.</Trans>,
    <Trans>With Palau Digital Residency ID, you can make an extension of 180 days of stay per entry. </Trans>,
    <Trans>
      Many countries typically don't tax their citizens or foreigners if they stay for less than 180 days. Notable
      exceptions do exist, such as the United States.
    </Trans>,
    <Trans>Please always stay on top of the tax obligations in your current country of residence.</Trans>,
  ]

  const PEOPLE_SAYS = [
    {
      icon: '/img/index/face/1.png',
      title: 'e.l.m',
      desc: '@e_l_m_13',
      content:
        'Have you checked out @RNS_global? They may be into the conversation. I got their ID and KYC’d on Kucoin with it. It’s a legit program to help people stay on board.',
    },
    {
      icon: '/img/index/face/2.png',
      title: 'Max Salvato',
      desc: '@Massy_11',
      content:
        'Officially approved @RNS_global 🎉Now I only dream of building few vlogs and reels/TikTok in Palau paradise 🎉🥰',
    },
    {
      icon: '/img/index/face/3.png',
      title: 'Manveer',
      desc: '@manveerxyz',
      content:
        'Applying for @RNS_global and it feels so surreal interacting with a government website that has UX as good as a consumer-facing software company.',
    },
    {
      icon: '/img/index/face/4.png',
      title: 'kfmroot',
      desc: '@rootkfm',
      content:
        'Fantastic team behind the project and the physical cards are delivered quite quickly, already used for taking flights multiple times and never had any issues.',
    },
    {
      icon: '/img/index/face/5.png',
      title: 'UI Vasily',
      desc: '@vasidom',
      content: 'I just renewed my for 5 years! Great idea, utilization and roadmap looks really promising!',
    },
    {
      icon: '/img/index/face/6.png',
      title: 'Robert Hintze',
      desc: '@RobertHintze',
      content:
        'Wow… How come this has passed under my sovereignty loving nose… Are we living a different timeline or what 😁💪🏻 Anyway thanks a lot!',
    },
  ]

  useEffect(() => {
    handleJumpBid()
    //用户打开首页发送连接
    ipServices.trackUser()
    if (localStorage.getItem('rns-cookie-policy') !== 'true') {
      setDisplay(true)
    }
  }, [])

  const handleCloseCookiePolicy = () => {
    localStorage.setItem('rns-cookie-policy', 'true')
    setDisplay(false)
  }

  const handleJumpBid = async () => {
    const result = await ipServices.checkIp()
    if (result.status.code === 200) {
      if (result.data.country === 'Palau') {
        if (localStorage.getItem('is_load_bid_page') !== 'true') {
          window.location.href = '/bid'
        }
      }
    }
  }

  useEffect(() => {
    const params: any = getUrlParams(location.search)
    if (params.rc_by) {
      window.localStorage.setItem('rns-referral-code', params.rc_by)
    }

    // const checkIp = async () => {
    //   const result = await services.checkIp()
    //   if (result.status.code === 200) {
    //     if (result.data.country === 'Estonia' && result.data.activity_status === true) {
    //       const currentTime = new Date().getTime()
    //       const previousTime = Number(localStorage.getItem('rns-japan-dialog') || 0)
    //       if (currentTime - previousTime > 60 * 60 * 1000) {
    //         localStorage.setItem('rns-japan-dialog', currentTime + '')
    //         setOpen(true)
    //       }
    //     }
    //   }
    // }
    // if (!globalStore.token) {
    //   checkIp()
    // }

    if (!window.localStorage.getItem('rns-law-tips')) {
      setOpen(true)
    }
  }, [])

  const URL = '/app/palauidinfo'

  const GUIDE_URL = '/app/guide'

  const handleClose = () => {
    setOpen(false)
  }

  const handleRegister = () => {
    window.location.href = '/app/register'
  }

  return (
    <div>
      <NextSeo
        title="rns.id - Web 3.0 Digital ID"
        description="Sovereignty-Backed Identification Card supported by Blockchain and Web 3.0"
        canonical="https://rns.id/"
        twitter={{
          handle: '@RNS_global',
          site: '@www.rns.id',
          cardType: 'summary_large_image',
        }}
      />
      {/* 活动 */}
      {/* <div className={styles.activityBanner}>
        <div className={styles.innerActivityBanner}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
              d="M25.3334 17.3332C24.9556 17.3332 24.6389 17.2055 24.3834 16.9499C24.1278 16.6944 24 16.3777 24 15.9999C24 15.6221 24.1278 15.3055 24.3834 15.0499C24.6389 14.7944 24.9556 14.6666 25.3334 14.6666H28C28.3778 14.6666 28.6945 14.7944 28.95 15.0499C29.2056 15.3055 29.3334 15.6221 29.3334 15.9999C29.3334 16.3777 29.2056 16.6944 28.95 16.9499C28.6945 17.2055 28.3778 17.3332 28 17.3332H25.3334ZM24.5334 25.8666L22.4 24.2666C22.0889 24.0444 21.9111 23.7555 21.8667 23.3999C21.8222 23.0444 21.9111 22.711 22.1334 22.3999C22.3556 22.0888 22.6445 21.911 23 21.8666C23.3556 21.8221 23.6889 21.911 24 22.1332L26.1334 23.7332C26.4445 23.9555 26.6222 24.2444 26.6667 24.5999C26.7111 24.9555 26.6222 25.2888 26.4 25.5999C26.1778 25.911 25.8889 26.0888 25.5334 26.1332C25.1778 26.1777 24.8445 26.0888 24.5334 25.8666ZM24 9.86658C23.6889 10.0888 23.3556 10.1777 23 10.1332C22.6445 10.0888 22.3556 9.91102 22.1334 9.59991C21.9111 9.2888 21.8222 8.95547 21.8667 8.59991C21.9111 8.24436 22.0889 7.95547 22.4 7.73325L24.5334 6.13325C24.8445 5.91102 25.1778 5.82214 25.5334 5.86658C25.8889 5.91102 26.1778 6.0888 26.4 6.39991C26.6222 6.71102 26.7111 7.04436 26.6667 7.39991C26.6222 7.75547 26.4445 8.04436 26.1334 8.26658L24 9.86658ZM6.66669 25.3332V19.9999H5.33335C4.60002 19.9999 3.97224 19.7388 3.45002 19.2166C2.9278 18.6944 2.66669 18.0666 2.66669 17.3332V14.6666C2.66669 13.9332 2.9278 13.3055 3.45002 12.7832C3.97224 12.261 4.60002 11.9999 5.33335 11.9999H10.6667L15.3 9.19991C15.7445 8.93325 16.1945 8.93325 16.65 9.19991C17.1056 9.46658 17.3334 9.85547 17.3334 10.3666V21.6332C17.3334 22.1444 17.1056 22.5332 16.65 22.7999C16.1945 23.0666 15.7445 23.0666 15.3 22.7999L10.6667 19.9999H9.33335V25.3332H6.66669ZM18.6667 20.4666V11.5332C19.2667 12.0666 19.75 12.7166 20.1167 13.4832C20.4834 14.2499 20.6667 15.0888 20.6667 15.9999C20.6667 16.911 20.4834 17.7499 20.1167 18.5166C19.75 19.2832 19.2667 19.9332 18.6667 20.4666ZM14.6667 19.2666V12.7332L11.4 14.6666H5.33335V17.3332H11.4L14.6667 19.2666Z"
              fill="#921F1F"
            />
          </svg>
          Join the RNS Special Event: DR Inauguration Ceremony with Palau President and Vitalik on August 28th in Palau!
          <Button
            text="Sign Up Now"
            onClick={() => {
              window.location.href = '/ceremony'
            }}
          />
        </div>
      </div> */}
      <CommonHeader2 wrapClassName={styles.indexWrapHeader2} />
      <div className={classnames(styles.page, {})}>
        <Sidebar2 />
        <div className={classnames(styles.bannerWrap)}>
          <Swiper
            autoplay={false}
            onSlideChangeTransitionEnd={(swiper) => {
              clearTimeout(timer)
              if (swiper.activeIndex === 2) {
                timer = setTimeout(() => {
                  swiper.slideNext()
                }, 3000)
              }
            }}
            onAutoplayPause={() => {
              clearTimeout(timer)
            }}
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            loopFillGroupWithBlank={true}
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            className={styles.swiperBanner}
          >
            <SwiperSlide className={classnames(styles.banner, styles.banner1)} key={0}>
              <div className={styles.bannerLeft}>
                <div className={styles.web3Id}>
                  <h1 className={styles.legal}>Palau ID</h1>
                  <div className={styles.program}>
                    <div className={styles.dashes}></div>
                    <div className={styles.desc}>
                      <Trans>Digital Residency Program</Trans>
                    </div>
                  </div>
                </div>
                <div className={styles.benefits}>
                  {BENEFITS.map((item: any, index) => {
                    return (
                      <div key={index} className={styles.benefitItem}>
                        {item.icon}
                        {item.text}
                      </div>
                    )
                  })}
                </div>
                <div className={styles.btnGroup}>
                  <a href={URL}>
                    <Button className={styles.indexCustomBtn} text={(<Trans>Apply Now for $248</Trans>) as any} />
                  </a>
                  <a href="https://www.youtube.com/watch?v=BMWZDPKvsS8" target="_blank" rel="noreferrer">
                    <Button className={styles.indexCustomBtn} ghost>
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path
                          d="M11.4 15.75L16.275 12.625C16.5083 12.475 16.625 12.2667 16.625 12C16.625 11.7333 16.5083 11.525 16.275 11.375L11.4 8.25C11.15 8.08333 10.8958 8.07083 10.6375 8.2125C10.3792 8.35417 10.25 8.575 10.25 8.875V15.125C10.25 15.425 10.3792 15.6458 10.6375 15.7875C10.8958 15.9292 11.15 15.9167 11.4 15.75ZM12.75 22C11.3667 22 10.0667 21.7375 8.85 21.2125C7.63333 20.6875 6.575 19.975 5.675 19.075C4.775 18.175 4.0625 17.1167 3.5375 15.9C3.0125 14.6833 2.75 13.3833 2.75 12C2.75 10.6167 3.0125 9.31667 3.5375 8.1C4.0625 6.88333 4.775 5.825 5.675 4.925C6.575 4.025 7.63333 3.3125 8.85 2.7875C10.0667 2.2625 11.3667 2 12.75 2C14.1333 2 15.4333 2.2625 16.65 2.7875C17.8667 3.3125 18.925 4.025 19.825 4.925C20.725 5.825 21.4375 6.88333 21.9625 8.1C22.4875 9.31667 22.75 10.6167 22.75 12C22.75 13.3833 22.4875 14.6833 21.9625 15.9C21.4375 17.1167 20.725 18.175 19.825 19.075C18.925 19.975 17.8667 20.6875 16.65 21.2125C15.4333 21.7375 14.1333 22 12.75 22ZM12.75 20C14.9833 20 16.875 19.225 18.425 17.675C19.975 16.125 20.75 14.2333 20.75 12C20.75 9.76667 19.975 7.875 18.425 6.325C16.875 4.775 14.9833 4 12.75 4C10.5167 4 8.625 4.775 7.075 6.325C5.525 7.875 4.75 9.76667 4.75 12C4.75 14.2333 5.525 16.125 7.075 17.675C8.625 19.225 10.5167 20 12.75 20Z"
                          fill="#232323"
                        />
                      </svg>
                      <Trans>Palau ID Guide</Trans>
                    </Button>
                  </a>
                </div>
              </div>
              <div className={styles.mobileCard}>
                <img src="/img/index/card.png?v=1" alt="palau id" />
              </div>
            </SwiperSlide>
            <SwiperSlide className={classnames(styles.banner, styles.banner2)} key={1}>
              <div className={styles.bannerLeft}>
                <div className={styles.web3Id}>
                  <h1 className={styles.legal}>Legal DID</h1>
                  <div className={styles.program}>
                    <div className={styles.desc}>
                      <Trans>
                        Unlock the future of identity with our LDID (Existing Jurisdiction) service. Take your legal
                        identity to the blockchain.
                      </Trans>
                    </div>
                  </div>
                </div>
                <div className={styles.benefits}>
                  {BENEFITS2.map((item: any, index) => {
                    return (
                      <div key={index} className={styles.benefitItem}>
                        {item.icon}
                        {item.text}
                      </div>
                    )
                  })}
                </div>
                <div className={styles.btnGroup}>
                  <a href={'/app/LDIDinfo'}>
                    <Button className={styles.indexCustomBtn} text={(<Trans>Start Now</Trans>) as any} />
                  </a>
                  <a href="https://www.youtube.com/watch?v=vEqeJR2YnW8" target="_blank" rel="noreferrer">
                    <Button className={styles.indexCustomBtn} ghost>
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path
                          d="M11.4 15.75L16.275 12.625C16.5083 12.475 16.625 12.2667 16.625 12C16.625 11.7333 16.5083 11.525 16.275 11.375L11.4 8.25C11.15 8.08333 10.8958 8.07083 10.6375 8.2125C10.3792 8.35417 10.25 8.575 10.25 8.875V15.125C10.25 15.425 10.3792 15.6458 10.6375 15.7875C10.8958 15.9292 11.15 15.9167 11.4 15.75ZM12.75 22C11.3667 22 10.0667 21.7375 8.85 21.2125C7.63333 20.6875 6.575 19.975 5.675 19.075C4.775 18.175 4.0625 17.1167 3.5375 15.9C3.0125 14.6833 2.75 13.3833 2.75 12C2.75 10.6167 3.0125 9.31667 3.5375 8.1C4.0625 6.88333 4.775 5.825 5.675 4.925C6.575 4.025 7.63333 3.3125 8.85 2.7875C10.0667 2.2625 11.3667 2 12.75 2C14.1333 2 15.4333 2.2625 16.65 2.7875C17.8667 3.3125 18.925 4.025 19.825 4.925C20.725 5.825 21.4375 6.88333 21.9625 8.1C22.4875 9.31667 22.75 10.6167 22.75 12C22.75 13.3833 22.4875 14.6833 21.9625 15.9C21.4375 17.1167 20.725 18.175 19.825 19.075C18.925 19.975 17.8667 20.6875 16.65 21.2125C15.4333 21.7375 14.1333 22 12.75 22ZM12.75 20C14.9833 20 16.875 19.225 18.425 17.675C19.975 16.125 20.75 14.2333 20.75 12C20.75 9.76667 19.975 7.875 18.425 6.325C16.875 4.775 14.9833 4 12.75 4C10.5167 4 8.625 4.775 7.075 6.325C5.525 7.875 4.75 9.76667 4.75 12C4.75 14.2333 5.525 16.125 7.075 17.675C8.625 19.225 10.5167 20 12.75 20Z"
                          fill="#232323"
                        />
                      </svg>
                      <Trans>Legal DID Guide</Trans>
                    </Button>
                  </a>
                </div>
              </div>

              <div className={styles.mobileCard}>
                <img src="/img/index/card2.png" alt="legal did" />
              </div>
            </SwiperSlide>
          </Swiper>
          <InView
            as="div"
            onChange={(inView, entry) => {
              Eventemitter.emit('inside_the_home_screen', inView)
            }}
          >
            <div></div>
          </InView>
        </div>
        <div className={styles.Frame1071}>
          <div className={styles.Frame10117}>
            <div className={styles.Frame867}>
              <div className={styles.Frame8671}>
                <Trans>Featured Digital Residents</Trans>
              </div>
            </div>
            <div className={styles.Frame1070}>
              <div className={styles.Frame10701}>
                <div className={styles.Rectangle8528} style={{ backgroundImage: 'url(/img/index/newP11.png?v=1)' }} />
                <div className={styles.Frame1068}>
                  <div className={styles.Frame10681}>
                    <div className={styles.Frame10661}>Tim Draper</div>
                    <div className={styles.Frame10662}>
                      <Trans>
                        On February 1st, 2022, President Surangel Whipps, Jr. hosted an Inauguration Ceremony with
                        Founding Digital Resident Tim Draper.
                      </Trans>
                    </div>
                  </div>
                  <div className={styles.Frame836}>
                    <a
                      className={styles.Frame827}
                      href="https://twitter.com/RNS_global/status/1488659644364906496"
                      target="_blank"
                    >
                      <div className={styles.Frame8271}>
                        <Trans>See on Twitter</Trans>
                      </div>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14 7.0625L5.58333 15.4792C5.43056 15.6319 5.25694 15.7049 5.0625 15.6979C4.86806 15.691 4.69444 15.6111 4.54167 15.4583C4.38889 15.3056 4.3125 15.1285 4.3125 14.9271C4.3125 14.7257 4.38889 14.5486 4.54167 14.3958L12.9375 6H8.7481C8.53615 6 8.35847 5.92855 8.21508 5.78565C8.07169 5.64273 8 5.46565 8 5.2544C8 5.04313 8.07181 4.86458 8.21544 4.71875C8.35906 4.57292 8.53703 4.5 8.74933 4.5H14.744C14.9563 4.5 15.1354 4.57181 15.2812 4.71544C15.4271 4.85906 15.5 5.03703 15.5 5.24933V11.244C15.5 11.4563 15.4285 11.6354 15.2856 11.7812C15.1427 11.9271 14.9656 12 14.7544 12C14.5431 12 14.3646 11.9283 14.2188 11.7849C14.0729 11.6415 14 11.4639 14 11.2519V7.0625Z"
                          fill="#0355BF"
                        />
                      </svg>
                    </a>
                    <a className={styles.Frame827} href="https://www.youtube.com/watch?v=DZdYvVifZK4" target="_blank">
                      <div className={styles.Frame8271}>
                        <Trans>Watch the video</Trans>
                      </div>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14 7.0625L5.58333 15.4792C5.43056 15.6319 5.25694 15.7049 5.0625 15.6979C4.86806 15.691 4.69444 15.6111 4.54167 15.4583C4.38889 15.3056 4.3125 15.1285 4.3125 14.9271C4.3125 14.7257 4.38889 14.5486 4.54167 14.3958L12.9375 6H8.7481C8.53615 6 8.35847 5.92855 8.21508 5.78565C8.07169 5.64273 8 5.46565 8 5.2544C8 5.04313 8.07181 4.86458 8.21544 4.71875C8.35906 4.57292 8.53703 4.5 8.74933 4.5H14.744C14.9563 4.5 15.1354 4.57181 15.2812 4.71544C15.4271 4.85906 15.5 5.03703 15.5 5.24933V11.244C15.5 11.4563 15.4285 11.6354 15.2856 11.7812C15.1427 11.9271 14.9656 12 14.7544 12C14.5431 12 14.3646 11.9283 14.2188 11.7849C14.0729 11.6415 14 11.4639 14 11.2519V7.0625Z"
                          fill="#0355BF"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className={styles.Frame10701}>
                <div className={styles.Rectangle8528} style={{ backgroundImage: 'url(/img/index/newP12.png?v=1)' }} />
                <div className={styles.Frame1068}>
                  <div className={styles.Frame10681}>
                    <div className={styles.Frame10661}>Vitalik Buterin</div>
                    <div className={styles.Frame10662}>
                      <Trans>
                        Ethereum co-founder, minted Voyager Pass #1430 at vitalik.eth, granting official Palau Digital
                        Resident status and supporting his belief in individual sovereignty.
                      </Trans>
                    </div>
                  </div>
                  <div className={styles.Frame836}>
                    <a
                      className={styles.Frame827}
                      href="https://twitter.com/RNS_global/status/1610430656223318019"
                      target="_blank"
                    >
                      <div className={styles.Frame8271}>
                        <Trans>See on Twitter</Trans>
                      </div>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14 7.0625L5.58333 15.4792C5.43056 15.6319 5.25694 15.7049 5.0625 15.6979C4.86806 15.691 4.69444 15.6111 4.54167 15.4583C4.38889 15.3056 4.3125 15.1285 4.3125 14.9271C4.3125 14.7257 4.38889 14.5486 4.54167 14.3958L12.9375 6H8.7481C8.53615 6 8.35847 5.92855 8.21508 5.78565C8.07169 5.64273 8 5.46565 8 5.2544C8 5.04313 8.07181 4.86458 8.21544 4.71875C8.35906 4.57292 8.53703 4.5 8.74933 4.5H14.744C14.9563 4.5 15.1354 4.57181 15.2812 4.71544C15.4271 4.85906 15.5 5.03703 15.5 5.24933V11.244C15.5 11.4563 15.4285 11.6354 15.2856 11.7812C15.1427 11.9271 14.9656 12 14.7544 12C14.5431 12 14.3646 11.9283 14.2188 11.7849C14.0729 11.6415 14 11.4639 14 11.2519V7.0625Z"
                          fill="#0355BF"
                        />
                      </svg>
                    </a>
                    <a
                      className={styles.Frame827}
                      href="https://opensea.io/assets/ethereum/0x87acae6df21385a74ed4fb55a1a29354e9bdc6c1/1430"
                      target="_blank"
                    >
                      <div className={styles.Frame8271}>
                        <Trans>View on OpenSea</Trans>
                      </div>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14 7.0625L5.58333 15.4792C5.43056 15.6319 5.25694 15.7049 5.0625 15.6979C4.86806 15.691 4.69444 15.6111 4.54167 15.4583C4.38889 15.3056 4.3125 15.1285 4.3125 14.9271C4.3125 14.7257 4.38889 14.5486 4.54167 14.3958L12.9375 6H8.7481C8.53615 6 8.35847 5.92855 8.21508 5.78565C8.07169 5.64273 8 5.46565 8 5.2544C8 5.04313 8.07181 4.86458 8.21544 4.71875C8.35906 4.57292 8.53703 4.5 8.74933 4.5H14.744C14.9563 4.5 15.1354 4.57181 15.2812 4.71544C15.4271 4.85906 15.5 5.03703 15.5 5.24933V11.244C15.5 11.4563 15.4285 11.6354 15.2856 11.7812C15.1427 11.9271 14.9656 12 14.7544 12C14.5431 12 14.3646 11.9283 14.2188 11.7849C14.0729 11.6415 14 11.4639 14 11.2519V7.0625Z"
                          fill="#0355BF"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* pc 轮播 */}
          <div className={styles.MaskGroup}>
            <Swiper
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              slidesPerView={3}
              spaceBetween={30}
              loop={true}
              loopFillGroupWithBlank={true}
              modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
            >
              {PEOPLE_SAYS.map((item, index) => {
                return (
                  <SwiperSlide className={styles.Frame1066} key={index}>
                    <div className={styles.Frame1098}>
                      <img className={styles.Image143} src={item.icon} alt="face" />
                      <div className={styles.Frame10127}>
                        <div className={styles.ELM}>{item.title}</div>
                        <div className={styles.ELM13}>{item.desc}</div>
                      </div>
                    </div>
                    <div className={styles.Frame10981}>{item.content}</div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
          {/* ipad 轮播 */}
          <div className={styles.MaskGroup1}>
            <Swiper
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              slidesPerView={2}
              spaceBetween={30}
              loop={true}
              loopFillGroupWithBlank={true}
              modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
            >
              {PEOPLE_SAYS.map((item, index) => {
                return (
                  <SwiperSlide className={styles.Frame1066} key={index}>
                    <div className={styles.Frame1098}>
                      <img className={styles.Image143} src={item.icon} alt="face" />
                      <div className={styles.Frame10127}>
                        <div className={styles.ELM}>{item.title}</div>
                        <div className={styles.ELM13}>{item.desc}</div>
                      </div>
                    </div>
                    <div className={styles.Frame10981}>{item.content}</div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
          {/* mobile 轮播 */}
          <div className={styles.MaskGroup2}>
            <Swiper
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              loopFillGroupWithBlank={true}
              modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
            >
              {PEOPLE_SAYS.map((item, index) => {
                return (
                  <SwiperSlide className={styles.Frame1066} key={index}>
                    <div className={styles.Frame1098}>
                      <img className={styles.Image143} src={item.icon} alt="face" />
                      <div className={styles.Frame10127}>
                        <div className={styles.ELM}>{item.title}</div>
                        <div className={styles.ELM13}>{item.desc}</div>
                      </div>
                    </div>
                    <div className={styles.Frame10981}>{item.content}</div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>

        <div className={classnames(styles.Frame1071, styles.Frame1072)}>
          <div className={styles.Frame10117}>
            <div className={styles.Frame867}>
              <div className={styles.Frame8671}>
                <Trans>In the Media</Trans>
              </div>
            </div>
            <div className={styles.Frame1070}>
              <div className={styles.Frame10702}>
                <img className={styles.Frame107021} src="/img/index/media1.png" alt="BBC World New" />
                <div className={styles.Frame107022}>
                  <Trans>BBC World News - Palau's Digital Residency Program</Trans>
                </div>
                <div className={styles.Frame107023}>
                  <Trans>
                    Unveiling a new era of digital possibilities, this immersive feature provides a comprehensive look
                    at how the small Pacific island nation is reshaping the future of work and global connectivity.
                  </Trans>
                </div>
                <a className={styles.Frame107024} href="https://www.youtube.com/watch?v=YOuLaKqO_pU" target="_blank">
                  <Trans>Watch the video</Trans>{' '}
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14 7.0625L5.58333 15.4792C5.43056 15.6319 5.25694 15.7049 5.0625 15.6979C4.86806 15.691 4.69444 15.6111 4.54167 15.4583C4.38889 15.3056 4.3125 15.1285 4.3125 14.9271C4.3125 14.7257 4.38889 14.5486 4.54167 14.3958L12.9375 6H8.7481C8.53615 6 8.35847 5.92855 8.21508 5.78565C8.07169 5.64273 8 5.46565 8 5.2544C8 5.04313 8.07181 4.86458 8.21544 4.71875C8.35906 4.57292 8.53703 4.5 8.74933 4.5H14.744C14.9563 4.5 15.1354 4.57181 15.2812 4.71544C15.4271 4.85906 15.5 5.03703 15.5 5.24933V11.244C15.5 11.4563 15.4285 11.6354 15.2856 11.7812C15.1427 11.9271 14.9656 12 14.7544 12C14.5431 12 14.3646 11.9283 14.2188 11.7849C14.0729 11.6415 14 11.4639 14 11.2519V7.0625Z"
                      fill="#0355BF"
                    />
                  </svg>
                </a>
              </div>
              <div className={styles.Frame10702}>
                <img className={styles.Frame107021} src="/img/index/media2.png" alt="The President of Palau" />
                <div className={styles.Frame107022}>
                  <Trans>The President of Palau | Network State Podcast with Balaji</Trans>
                </div>
                <div className={styles.Frame107023}>
                  <Trans>
                    The President of Palau and Balaji discuss how the country is quickly becoming a leader in the
                    digital world, trying out new things — from on-chain identity to drone delivery.
                  </Trans>
                </div>
                <a className={styles.Frame107024} href="https://www.youtube.com/watch?v=3FuQvG2dmM4" target="_blank">
                  <Trans>Watch the video</Trans>{' '}
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14 7.0625L5.58333 15.4792C5.43056 15.6319 5.25694 15.7049 5.0625 15.6979C4.86806 15.691 4.69444 15.6111 4.54167 15.4583C4.38889 15.3056 4.3125 15.1285 4.3125 14.9271C4.3125 14.7257 4.38889 14.5486 4.54167 14.3958L12.9375 6H8.7481C8.53615 6 8.35847 5.92855 8.21508 5.78565C8.07169 5.64273 8 5.46565 8 5.2544C8 5.04313 8.07181 4.86458 8.21544 4.71875C8.35906 4.57292 8.53703 4.5 8.74933 4.5H14.744C14.9563 4.5 15.1354 4.57181 15.2812 4.71544C15.4271 4.85906 15.5 5.03703 15.5 5.24933V11.244C15.5 11.4563 15.4285 11.6354 15.2856 11.7812C15.1427 11.9271 14.9656 12 14.7544 12C14.5431 12 14.3646 11.9283 14.2188 11.7849C14.0729 11.6415 14 11.4639 14 11.2519V7.0625Z"
                      fill="#0355BF"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.mediaList}>
          {[1, 2, 3, 4, 5, 6, 7].map((item) => {
            return <img key={item} src={`/img/index/media/${item}.png`} alt="media" className={styles.mediaPic} />
          })}
        </div>

        <section className={styles.xMLParser}>
          <div className={styles.jSONParser}>
            <h1 className={styles.voyagerPass}>Voyager Pass</h1>
            <div className={styles.tabulator}>
              <div className={styles.chartCreator}>
                <button className={styles.tableBuilder}>
                  <img className={styles.image152Icon} src="/img/index/image-152@2x.png" alt="voyager pass" />
                </button>
                <div className={styles.diagramMaker}>
                  <h3 className={styles.enhancedRnsRewards}>
                    <Trans>Enhanced RNS Rewards</Trans>
                  </h3>
                  <div className={styles.receive150Bonus}>
                    <Trans>Enjoy up to 40% discount on selected purchases.</Trans>
                  </div>
                </div>
              </div>
              <div className={styles.chartCreator1}>
                <button className={styles.image153Wrapper}>
                  <img className={styles.image153Icon} src="/img/index/image-153@2x.png" alt="voyager pass" />
                </button>
                <div className={styles.priorityAccessFeaturesParent}>
                  <h3 className={styles.priorityAccess}>
                    <Trans>Priority Access & Features</Trans>
                  </h3>
                  <div className={styles.benefitFromExpedited}>
                    <Trans>Benefit from expedited access to RNS services and upcoming features.</Trans>
                  </div>
                </div>
              </div>
              <div className={styles.chartCreator2}>
                <button className={styles.image150Wrapper}>
                  <img className={styles.image150Icon} src="/img/index/image-150@2x.png" alt="voyager pass" />
                </button>
                <div className={styles.exclusiveTravelSavingsParent}>
                  <h3 className={styles.exclusiveTravelSavings}>
                    <Trans>Exclusive Travel Savings</Trans>
                  </h3>
                  <div className={styles.unlockSignificantDiscounts}>
                    <Trans>Unlock significant discounts on hotels, flights, and car rentals.</Trans>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.sliderController}>
              <div className={styles.readyToSeizeTheDigitalAdvParent}>
                <h1 className={styles.readyToSeize}>
                  <Trans>Ready to Seize the Digital Advantage?</Trans>
                </h1>
                <div className={styles.labelMaker}>
                  <div className={styles.formBuilder}>
                    <Button
                      text={(<Trans>Purchase Voyager Pass</Trans>) as any}
                      onClick={() => {
                        window.open('https://opensea.io/collection/voyager-pass')
                      }}
                    />
                    <Button
                      text={(<Trans>Check Redeemability</Trans>) as any}
                      ghost
                      onClick={() => {
                        window.open('/checkRedeemability')
                      }}
                    />
                  </div>
                  <div className={styles.selectField}>
                    <a className={styles.textLink} href={GUIDE_URL} target="_blank">
                      <img
                        className={styles.developerGuideIcon}
                        loading="lazy"
                        alt="guide"
                        src="/img/index/developer-guide.svg"
                      />
                      <div className={styles.button4}>
                        <Trans>Voyager Pass Redemption Guide</Trans>
                      </div>
                      <img className={styles.developerGuideIcon1} alt="guide" src="/img/index/developer-guide.svg" />
                    </a>
                  </div>
                </div>
              </div>
              <div className={styles.dataAggregator}>
                <div className={styles.wrapperImage131}>
                  <img className={styles.image131Icon} loading="lazy" alt="box" src="/img/index/image-131@2x.png" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <AgreeCookiePolicy handleClose={handleCloseCookiePolicy} display={display} />
        <CustomDialog
          status={0}
          open={open}
          setOpen={setOpen}
          onClickBtn2={() => {
            window.localStorage.setItem('rns-law-tips', 'true')
            handleClose()
          }}
          btnText2={(<Trans>Got It</Trans>) as any}
        >
          <div className={styles.lawTitle}>{LAW_TITLE}</div>
          <ul className={styles.lawTips}>
            {LAW_TIPS.map((item, index) => {
              return <li key={index}>{item}</li>
            })}
          </ul>
        </CustomDialog>
        {/* <MuiDialog2
          setOpen={setOpen}
          open={open}
          className={styles.japanOuterDialog}
          disableScrollLock
          onClose={handleClose}
        >
          <div className={styles.japanDialog}>
            <div className={styles.japanInnerDialog}>
              <svg
                className={styles.close}
                onClick={handleClose}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 13.85L7.32501 18.525C7.07501 18.775 6.76667 18.9 6.40001 18.9C6.03334 18.9 5.72501 18.775 5.47501 18.525C5.22501 18.275 5.10001 17.9667 5.10001 17.6C5.10001 17.2333 5.22501 16.925 5.47501 16.675L10.15 12L5.47501 7.32501C5.22501 7.07501 5.10001 6.76667 5.10001 6.40001C5.10001 6.03334 5.22501 5.72501 5.47501 5.47501C5.72501 5.22501 6.03334 5.10001 6.40001 5.10001C6.76667 5.10001 7.07501 5.22501 7.32501 5.47501L12 10.15L16.675 5.47501C16.925 5.22501 17.2333 5.10001 17.6 5.10001C17.9667 5.10001 18.275 5.22501 18.525 5.47501C18.775 5.72501 18.9 6.03334 18.9 6.40001C18.9 6.76667 18.775 7.07501 18.525 7.32501L13.85 12L18.525 16.675C18.775 16.925 18.9 17.2333 18.9 17.6C18.9 17.9667 18.775 18.275 18.525 18.525C18.275 18.775 17.9667 18.9 17.6 18.9C17.2333 18.9 16.925 18.775 16.675 18.525L12 13.85Z"
                  fill="#A0A8A7"
                />
              </svg>

              <div className={styles.img}>
                <Image src={'/img/index/sakura.png'} width={120} height={120} alt="" />
              </div>
              <div className={styles.text}>
                <Trans>
                  Fantastic! You appear to be a Japanese user! Great news is waiting for you! For all Japanese users, we
                  are offering a unique redemption code that may be used as a $20 voucher. You will receive a code in
                  your email address once registration is complete.
                </Trans>
              </div>
              <div className={styles.btn} onClick={handleRegister}>
                <Trans>Sign Up Now</Trans>
              </div>
            </div>
          </div>
        </MuiDialog2> */}
      </div>
      <FooterFP />
    </div>
  )
}

export default Palau
